import React from "react";
import phoneIcon from "../assets/icons/phone-icon.png";
import websiteIcon from "../assets/icons/website-icon.png";
import addressIcon from "../assets/icons/address-icon.png";
import emailIcon from "../assets/icons/email-icon.png";
import logoVintGlobal from "../assets/logo/logoVintGlobal.png";
import signatureDesign from "../assets/signature/DesginAssinaturaVintGlobal.png";

function YourSignatureWithoutAPhoto({ userData }) {
  return (
    <div className="border-[2px] border-white">
      <div
        id="designWithoutAPhoto"
        className="relative flex 2xl:w-[45rem] 2xl:h-[16rem] xl:w-[35rem] xl:h-[13rem] lg:w-[28rem] lg:h-[10rem] border-none"
      >
        <img
          src={signatureDesign}
          className="absolute w-full h-full"
          alt="Signature Background"
        />

        <div className="flex flex-col items-start justify-center absolute lg:top-4 lg:left-3 2xl:top-5 2xl:left-5 2xl:space-y-2 lg:space-y-1 xl:top-5 xl:left-4 xl:space-y-1">
          <div className="flex w-[30%] lg:mb-2 xl:mb-2 2xl:mb-3">
            <img
              src={logoVintGlobal}
              className="w-full h-full object-contain"
              alt="Logo Vint Global"
            />
          </div>
          <div className="text-white 2xl:w-[15.5rem] xl:w-[14rem] lg:w-[11.3rem] 2xl:text-[21px] xl:text-[16.5px] lg:text-[13.5px]">
            <span className="font-Montserrat font-semibold text-left">
              {userData.name || "NOME SOBRENOME"}
            </span>
          </div>
          <div className="text-white 2xl:text-[13.5px] xl:text-[11px] lg:text-[9.5px]">
            <span className="font-Montserrat font-normal text-left xl:mb-1">
              {userData.position || "FUNÇÃO"}
            </span>
          </div>
          <div className="bg-white 2xl:w-16 xl:w-16 lg:w-12 h-[1px] rounded-xl"></div>
          <div className="text-white 2xl:w-60 xl:w-52 lg:w-[9rem] 2xl:text-[13.5px] xl:text-[11px] lg:text-[9.5px]">
            <span className="font-Montserrat font-normal lg:mt-1 xl:mt-2 2xl:mt-2 text-left">
              {userData.sector || "Setor"}
            </span>
          </div>
        </div>

        <div className="absolute 2xl:right-[15rem] 2xl:top-[6rem] xl:right-[10.4rem] xl:top-[4.7rem] lg:right-[7rem] lg:top-[3.9rem] flex flex-col items-center justify-center 2xl:space-y-4 xl:space-y-3 lg:space-y-2 font-medium">
          {[
            { icon: phoneIcon, value: userData.phone || "Celular" },
            { icon: emailIcon, value: userData.email || "E-mail" },
            { icon: websiteIcon, value: "vintglobal.com.br" },
            {
              icon: addressIcon,
              value: "R. Tibúrcio Cavalcante, 1563 Meireles, Fortaleza - CE",
            },
          ].map(({ icon, value }, index) => (
            <div
              key={index}
              className="text-white flex items-start justify-start 2xl:w-48 xl:w-[10.5rem] xl:space-x-2 lg:space-x-1 2xl:text-[11.3px] xl:text-[9px] lg:text-[7px] lg:w-[9rem]"
            >
              <img
                className="2xl:w-[1.1rem] xl:w-[0.8rem] lg:w-[0.7rem] object-contain"
                src={icon}
                alt="Icon"
              />
              <span className="font-Montserrat text-left">{value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default YourSignatureWithoutAPhoto;
