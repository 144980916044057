import React, { useState, useCallback } from "react";

// Componentes
import FillingFields from "./FillingFields";
import ActiveButtons from "./ActiveButtons";
import YourSignatureWithPhoto from "./YourSignatureWithPhoto";
import YourSignatureWithoutAPhoto from "./YourSignatureWithoutAPhoto";

// Assets
import IconVintGlobal from "../assets/icons/logoVintGlobal.png";

function MainScreen() {
  const [userData, setUserData] = useState({
    name: "",
    position: "",
    sector: "",
    phone: "",
    email: "",
  });
  const [image, setImage] = useState("");

  // Função para atualizar os dados do usuário
  const handleInputChange = useCallback((field, value) => {
    if (field === "name" || field === "position") {
      value = value.toUpperCase(); // Converte para maiúsculas
    }
    setUserData((prevData) => ({ ...prevData, [field]: value }));
  }, []);

  // Função para fazer o upload da imagem
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      // Verifica se é uma imagem
      const reader = new FileReader();
      reader.onloadend = () => setImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      // Lida com arquivos não-imagem (opcional)
      alert("Por favor, envie um arquivo de imagem válido.");
    }
  };

  // Função para validar o e-mail
  const isEmailValid = (email) =>
    email.trim() !== "" &&
    /^[a-zA-Z0-9._%+-]+@vintglobal\.com\.br$/.test(email);

  // Verificação de formulário completo
  const isFormComplete = ["name", "position", "sector", "email"].every(
    (field) =>
      userData[field].trim() !== "" &&
      (field !== "email" || isEmailValid(userData.email))
  );

  return (
    <main className="w-full h-screen flex items-center justify-center">
      <section className="w-1/2 h-full flex flex-col items-center justify-start 2xl:gap-10 xl:gap-6 lg:gap-6 bg-primary-lilac p-4">
        <div className="flex w-full flex-col xl:px-7 xl:mb-6 justify-center 2xl:mt-2 2xl:mb-0 text-center text-white font-Montserrat">
          <h1 className="2xl:text-[28px] xl:text-[25px] lg:text-[20px] lg:mb-2 font-semibold">
            Gerador de Assinatura
          </h1>
          <p className="2xl:text-[16px] 2xl:px-2 lg:text-[12px] lg:px-5 lg:mb-4 xl:mb-2 xl:text-[15px]">
            Aplicação para gerar sua assinatura. <br />
            Por favor, preencha todos os campos solicitados e escolha uma das
            opções abaixo:
          </p>
        </div>

        {/* Exibição da assinatura com foto ou sem foto */}
        <YourSignatureWithPhoto userData={userData} image={image} />
        <YourSignatureWithoutAPhoto userData={userData} />

        <div className="w-full flex items-center justify-center space-x-3 text-white font-Montserrat">
          <p className="flex items-center justify-center">
            Powered by{" "}
            <img
              className="w-8"
              src={IconVintGlobal}
              alt="Logotipo Vint Global"
            />
          </p>
        </div>
      </section>

      <section className="w-1/2 h-full bg-primary-gray flex flex-col items-center justify-center p-4">
        <FillingFields
          onInputChange={handleInputChange}
          onImageUpload={handleImageUpload}
        />
        <ActiveButtons
          email={userData.email}
          name={userData.name}
          position={userData.position}
          isFormComplete={isFormComplete} // Verifica se o formulário está completo
          isEmailValid={isEmailValid(userData.email)} // Passa a função de validação do e-mail
        />
      </section>
    </main>
  );
}

export default MainScreen;
