import React from "react";

const FillingFields = ({ onInputChange, onImageUpload }) => {
  const handleName = (e) => {
    const value = e.target.value;
    const isValid = /^[\p{L}\s´`~^]*$/u.test(value); // Permite letras Unicode, espaços e caracteres especiais

    if (isValid || value === "") {
      onInputChange("name", value); // Atualiza o valor apenas se for válido
    }
  };

  const handleAutoComplete = (e) => {
    let value = e.target.value;

    if (value.includes("@") && !value.includes("@vintglobal.com.br")) {
      value = value.split("@")[0] + "@vintglobal.com.br";
      onInputChange("email", value);
      e.target.value = value;
    } else {
      onInputChange("email", value);
    }
  };

  const handleNameKeyDown = (e) => {
    const validChars = /^[\p{L}\s´`~^]$/u; // Permite letras e caracteres especiais
    const controlKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
    ];

    // Verifica se a tecla pressionada não é válida e nem uma tecla de controle
    if (!validChars.test(e.key) && !controlKeys.includes(e.key)) {
      e.preventDefault(); // Previne a inserção de caracteres inválidos
    }
  };

  const handlePhoneInput = (event) => {
    let input = event.target.value.replace(/\D/g, "").substring(0, 11); // Remove tudo o que não for número e limita a 11 dígitos

    // Formatação do telefone
    let formattedPhone = input;
    if (input.length > 2) {
      formattedPhone = `(${input.substring(0, 2)}) ${input.substring(2)}`;
    }
    if (input.length > 7) {
      formattedPhone = `(${input.substring(0, 2)}) ${input.substring(
        2,
        7
      )}-${input.substring(7)}`;
    }

    // Atualiza o valor no campo de entrada
    event.target.value = formattedPhone;
    onInputChange("phone", formattedPhone); // Chama a função de atualização
  };

  return (
    <div className="flex flex-col items-start justify-center lg:gap-7 2xl:gap-12 2xl:mt-2 xl:gap-10">
      <input
        className="w-[90%] xl:w-[32.5rem] 2xl:w-[40rem] lg:w-[25rem] h-12 xl:h-14 2xl:h-16 px-4 rounded-[1.5rem] shadow-lg border-black border-[1px] focus:border-primary-lilac focus:outline-none"
        type="text"
        placeholder="Nome"
        maxLength={20}
        required
        onChange={handleName}
        onKeyDown={handleNameKeyDown}
      />
      <input
        className="w-[90%] xl:w-[32.5rem] 2xl:w-[40rem] lg:w-[25rem] h-12 xl:h-14 2xl:h-16 px-4 rounded-[1.5rem] shadow-lg border-black border-[1px] focus:border-primary-lilac focus:outline-none"
        type="text"
        placeholder="Função"
        maxLength={28}
        required
        onChange={(e) => onInputChange("position", e.target.value)}
      />
      <input
        type="file"
        accept="image/*"
        className="hidden"
        id="imageUploadInput"
        onChange={onImageUpload}
      />
      <div className="flex items-center justify-start">
        <label
          htmlFor="imageUploadInput"
          className="w-[90%] xl:w-[10.5rem] 2xl:w-[10.5rem] lg:w-[10.5rem] h-12 xl:h-14 2xl:h-16 px-4 rounded-[1.5rem] shadow-lg border-black border-[1px] bg-white focus:outline-none cursor-pointer flex items-center justify-start"
        >
          <span className="mr-3">Importar foto</span>
          <span class="material-symbols-outlined">upload</span>
        </label>
        <span className="lg:ml-2 xl:ml-10 2xl:ml-16 lg:text-[13px] xl:text-[15px] 2xl:text-[20px]">
          Recomendação de tamanho 400 x 400.
        </span>
      </div>
      <form action="#">
        <select
          className="w-[90%] xl:w-[32.5rem] 2xl:w-[40rem] lg:w-[25rem] h-12 xl:h-14 2xl:h-16 px-4 rounded-[1.5rem] shadow-lg border-black border-[1px] focus:border-primary-lilac focus:outline-none"
          name="Setores"
          id="sector"
          defaultValue=""
          onChange={(e) => onInputChange("sector", e.target.value)}
        >
          <option value="" disabled>
            Setor
          </option>
          <option value="Administrativo">Administrativo</option>
          <option value="Assessoria da Diretoria">Assessoria</option>
          <option value="Centro de Atendimento e Serviço (CAS)">
            Centro de Atendimento e Serviço (CAS)
          </option>
          <option value="Centro de Desenvolvimento de Sistemas (CDS)">
            Centro de Desenvolvimento de Sistemas (CDS)
          </option>
          <option value="Cloud">Cloud</option>
          <option value="Contabilidade">Contabilidade</option>
          <option value="Customer Success (CS)">Customer Success (CS)</option>
          <option value="Departamento Pessoal">Departamento Pessoal</option>
          <option value="Excelência Operacional (EDO)">
            Excelência Operacional (EDO)
          </option>
          <option value="Financeiro">Financeiro</option>
          <option value="Gente e Cultura (RH)">Gente e Cultura (RH)</option>
          <option value="Gestão de implementação de Projetos (GIP)">
            Gestão de implementação de Projetos (GIP)
          </option>
          <option value="Infraestrutura">Infraestrutura</option>
          <option value="Inovação">Inovação</option>
          <option value="Logística">Logística</option>
          <option value="Marketing">Marketing</option>
          <option value="Inteligência de Negócios e Inovação">
            Inteligência de Negócios e Inovação
          </option>
          <option value="Operacional">Operacional</option>
          <option value="Projetos Externos">Projetos Externos</option>
          <option value="Secretariado da Diretoria Geral">
            Secretariado da Diretoria Geral
          </option>
        </select>
      </form>
      <input
        className="w-[90%] xl:w-[32.5rem] 2xl:w-[40rem] lg:w-[25rem] h-12 xl:h-14 2xl:h-16 px-4 rounded-[1.5rem] shadow-lg border-black border-[1px] focus:border-primary-lilac focus:outline-none"
        type="tel"
        placeholder="Celular"
        onInput={handlePhoneInput}
        onChange={(e) => onInputChange("phone", e.target.value)}
      />
      <input
        className="w-[90%] xl:w-[32.5rem] 2xl:w-[40rem] lg:w-[25rem] h-12 xl:h-14 2xl:h-16 px-4 rounded-[1.5rem] shadow-lg border-black border-[1px] focus:border-primary-lilac focus:outline-none"
        type="email"
        placeholder="E-mail"
        maxLength={38}
        onInput={handleAutoComplete}
        required
        onChange={(e) => onInputChange("email", e.target.value)}
      />
    </div>
  );
};

export default FillingFields;
