import React from "react";
import ReactDOM from "react-dom/client"; // Para a versão 18 e superior do React
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import App from "./App";
import MainScreen from "./components/MainScreen";
import "./index.css";

// Criar o root da aplicação com createRoot
const root = ReactDOM.createRoot(document.getElementById("root"));

// Renderizando a aplicação com React.StrictMode para identificar problemas no desenvolvimento
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* <Route path="/" element={<App />} />  */}
        <Route path="/" element={<MainScreen />} />{" "}
        {/* Rota para a tela principal */}
      </Routes>
    </Router>
  </React.StrictMode>
);
